<template>
    <div class="information">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="information_header">
            <div class="information_header_tab">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="全部" name="0"></el-tab-pane>
                    <el-tab-pane  v-for="(item,index) in firstTypeList" :key="index" :label="item.name" :name="JSON.stringify(item.id)"></el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="information_main">
            <div class="information_main_left">
              <div class="title">全部资讯</div>
                <div class="information_main_left_list">
                    <div class="information_main_left_list_header">{{title}}</div>
                    <div class="information_main_left_list_item" v-for="(item,index) in industryList" :key="index" @click="goDetail(item)">
                        <p>
                            <span></span>
                            <span style="cursor:pointer;">{{item.informationTitle}}</span>
                        </p>
                        <p>{{item.intime}}</p>
                    </div>
                </div>
                <div class="information_main_left_pagination" v-if="industryList.length">
                    <el-pagination layout="prev, pager, next"  :total="count" @current-change="currentChange"></el-pagination>
                </div>
                <div class="coursediv_empty" v-if="!industryList.length">
                    <div class="coursediv_empty_img">
                        <img src="@/assets/class_empty.png" alt="">
                    </div>
                    <div class="coursediv_empty_text">无相关内容</div>
                </div>
            </div>

            <div class="information_main_right">
                <div class="information_main_right_freeLive" v-if="freeLiveList.length>0">
                    <div class="information_main_right_freeLive_header">
                        <span>免费直播</span>
                        <!-- <span @click="goMore()">更多 ></span> -->
                    </div>
                    <div class="information_main_right_freeLive_list">
                        <div class="information_main_right_freeLive_list_item" v-for="(item,index) in freeLiveList" :key="index" >
                            <div class="information_main_right_freeLive_list_item_left">
                                <img :src="item.teacherHeaderPath" alt="">
                                <span>{{item.teacherName}}</span>
                            </div>
                            <div class="information_main_right_freeLive_list_item_right">
                                <div class="information_main_right_freeLive_list_item_right_top">
                                    <span v-if="item.type==0">直播中</span>
                                    <span v-else style="width:1px"></span>
                                    <span>{{item.liveTIme}}</span>
                                </div>
                                <div class="information_main_right_freeLive_list_item_right_bottom">
                                    <p style="width:80%;">
                                        <span>{{item.liveName}}</span>
                                    </p>
                                    <button style="cursor:pointer;" v-if="item.type==0" @click="toLiveDetail(item)">进入直播</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="information_main_right_bottom">
                    <div class="information_main_right_bottom_icon">
                        <p>
                            <img src="@/assets/ask/information_icon.png" alt="">
                            <span>备考干货</span>
                        </p>
                        <p>
                            <img src="@/assets/ask/consult.png" alt="">
                            <span>财务资讯</span>
                        </p>
                        <p>
                            <img src="@/assets/ask/alarm.png" alt="">
                            <span>实时互动</span>
                        </p>
                    </div>
                    <div class="information_main_right_bottom_QRcode">
                        <img :src="website.qrCodeRight" alt />
                    </div>
                    <div class="information_main_right_bottom_text">下载APP，实时查看老师答疑</div>
                </div>
            </div>
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { first_types,information_list2,freelive_list } from "@/api/home";
import { information_firstType,information_detail } from "@/api/other";
import { wangzhanpeizhi } from "@/api/login";
export default {
    name:'information',
    components: {
        t_header,
        t_footer,
        // popup_login
    },
    data(){
        return{
            isSort:false,
            // todos:false,
            isType:false,
            activeName:'0',
            title:'',
            firstTypeList:[0],
            industryList:[],
            freeLiveList:[],
            firstTypeId:'',
            page:0,
            count:0,
            isUserInfo:false,
            website:{},
        }
    },
    mounted(){
        this.first_types()
        this.information_list2()
        this.freelive_list()
        this.wangzhanpeizhi();
    },
    methods:{
        wangzhanpeizhi(){
            wangzhanpeizhi().then(res=>{
                console.log("网站配置");
                if(res.data.code == 0){
                    this.website = res.data.data;
                }
            }).catch(error=>{
                console.log(error);
            })
        },
        // 父传子
        getTodos(e){
            console.log(e)
            this.isUserInfo=e
        },
        // pre(page){
        //     console.log(this.page);
        // },
        // next(page){
        //     console.log(this.page);
        // },
        currentChange(page){
            this.page = page-1;
            this.information_list2();
        },
        goMore(){
            let routeUrl =this.$router.resolve({
                path: '/liveList',
                query: {}
            })
            window.open(routeUrl.href, '_blank');
        },
        goDetail(item){
            console.log(item);
            let routeUrl =this.$router.resolve({
                path: '/informationDetail',
                query: {
                    informationId:item.informationId
                }
            })
            window.open(routeUrl.href, '_blank');
        },
        handleClick(tab,event){
            this.page = 0;
            if(tab.index == '0'){
                this.firstTypeId='';//this.firstTypeList[tab.index].id
            }else{
                this.firstTypeId=this.firstTypeList[tab.index-1].id
            }
            this.information_list2()
        },
        checkLogin(){
            const userInfo = localStorage.getItem("setuserInfo");
            if(userInfo==null || userInfo=='null'){
                this.isUserInfo=true;
                setTimeout(() => {
                    this.isUserInfo=false;
                }, 0);
                return false
            }else{
                return userInfo
            }
        },
        // 去直播间
        toLiveDetail(item){
            // console.log(item)
            // var url='https://live.polyv.cn/watch/'+item.channelId
            // window.open(url, '_blank');

            const userInfo = this.checkLogin();
            if(!userInfo){
                return false
            }

            let routeUrl = this.$router.resolve({
                path: '/liveVideo',
                query: {
                    channelId:item.channelId
                }
            });
            window.open(routeUrl.href, '_blank');

        },
        // 免费直播列表
        freelive_list(){
            freelive_list().then(res=>{
                console.log(res)
                if(res.data.code==0){
                    var arr = res.data.data.slice(0,4);
                    this.freeLiveList=arr;
                }else{
                    this.$message.error(res.data.msg);
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        first_types(){
            information_firstType().then(res=>{
                console.log(res)
                if(res.data.code==0){
                    // this.firstTypeList = this.firstTypeList.concat(res.data.data);
                    this.firstTypeList=res.data.data
                    
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 资讯列表
        information_list2(){
            var obj={
                firstTypeId:this.firstTypeId,
                page:this.page
            }
            information_list2(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.count = res.data.count;
                    var industryList=[]
                    for(var i in res.data.data){
                        if(res.data.data[i].intime.length>10){
                            res.data.data[i].intime_new=res.data.data[i].intime.slice(0,10)
                            industryList.push(res.data.data[i])
                        }
                    }
                    this.industryList=industryList
                }
            }).catch(error=>{
                console.log(error)
            })
        },
    }
}
</script>

<style>

</style>